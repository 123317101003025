<template>
  <v-btn x-large text icon color="red " @click="click" class="align-self-center">
    <v-icon
      v-if="settings.graphics && settings.graphics.mode == 'low'"
      class="align-center"
      color="red"
    >
      mdi-close-circle
    </v-icon>
    <v-img
      v-else
      contain
      max-height="40"
      max-width="40"
      :src="require('@/assets/island/BtnClose.png')"
    ></v-img>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
let closeSfx = new Audio(require("@/assets/sounds/sfx_click_4.wav"));
export default {
  components: {
    //
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: ["size", "callback"],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    click() {
      this.callback();
      closeSfx.play();
      closeSfx.volume = this.settings.audio.sfx * this.settings.audio.master;
    },
  },
};
</script>